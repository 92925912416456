"use client"

import React, { useCallback, useEffect, useRef } from "react"
import Image from "next/image"
import { cn } from "@/utils/cn"
import { type EmblaCarouselType, type EmblaEventType, type EmblaOptionsType } from "embla-carousel"
import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel from "embla-carousel-react"
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures"

import { CustomLink } from "@/components/ui/link"

import { NextButton, PrevButton, usePrevNextButtons } from "./EmblaCarouselArrowButtons"
import { useDotButton } from "./EmblaCarouselDotButton"

// DotButton,

Autoplay.globalOptions = { delay: 4000 }
const TWEEN_FACTOR_BASE = 0.2

type PropType = {
  slides: {
    title: string
    image: string
    altImage: string
    blurDataURL: string
    href: string
    ariaLabel: string
  }[]
  options?: EmblaOptionsType
  className?: string
}

const numberWithinRange = (number: number, min: number, max: number): number =>
  Math.min(Math.max(number, min), max)

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options, className } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [WheelGesturesPlugin(), Autoplay()])
  const tweenFactor = useRef(0)

  const { selectedIndex } = useDotButton(emblaApi)
  // , scrollSnaps, onDotButtonClick
  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi)

  const setTweenFactor = useCallback((emblaApi: EmblaCarouselType) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenOpacity = useCallback((emblaApi: EmblaCarouselType, eventName?: EmblaEventType) => {
    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()
    const slidesInView = emblaApi.slidesInView()
    const isScrollEvent = eventName === "scroll"

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress
      const slidesInSnap = engine.slideRegistry[snapIndex]

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target()

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target)

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress)
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress)
              }
            }
          })
        }

        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current)
        const opacity = numberWithinRange(tweenValue, 0.9, 1).toString()
        emblaApi.slideNodes()[slideIndex].style.opacity = opacity
      })
    })
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    setTweenFactor(emblaApi)
    tweenOpacity(emblaApi)
    emblaApi
      .on("reInit", setTweenFactor)
      .on("reInit", tweenOpacity)
      .on("scroll", tweenOpacity)
      .on("slideFocus", tweenOpacity)
  }, [emblaApi, setTweenFactor, tweenOpacity])

  return (
    <div className={cn(className, "embla")}>
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((item, index) => (
            <div className="embla__slide relative" key={index}>
              <CustomLink
                href={item.href}
                rel={"noreferrer noopener"}
                ariaLabel={item.ariaLabel}
                title={item.title}
                target={"_blank"}
                className="relative h-auto"
              >
                <Image
                  src={item.image}
                  alt={item.altImage}
                  style={{ height: "auto" }}
                  width={195}
                  height={304}
                  placeholder={"blur"}
                  blurDataURL={item.blurDataURL}
                  sizes="(max-width: 1024x) 200px, (max-width: 1280px) 200px, 200px"
                  loading="lazy"
                  quality={100}
                  className={cn(
                    index !== selectedIndex ? "opacity-50 transition-opacity" : "",
                    "embla__slide__img relative cursor-pointer rounded-lg shadow-md"
                  )}
                />
              </CustomLink>
            </div>
          ))}
        </div>
      </div>
      <div className="embla__controls">
        <div className="embla__buttons">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

        {/* <div className="embla__dots !hidden sm:!flex">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot h-8 w-8".concat(
                index === selectedIndex ? "embla__dot--selected" : ""
              )}
            />
          ))}
        </div> */}
      </div>
    </div>
  )
}

export default EmblaCarousel
