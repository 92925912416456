import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/components/sections/About.tsx\",\"import\":\"Dancing_Script\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"dancing\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/components/sections/Newsletter.tsx\",\"import\":\"Dancing_Script\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"dancing\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/sections/hero-carousel/EmblaCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MailerliteForm"] */ "/vercel/path0/src/components/sections/newsletter-mailerlite-form/Mailerliteform.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/src/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ui/blur-fade.tsx");
