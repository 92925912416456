"use client"

import { useEffect } from "react"
import { cn } from "@/utils/cn"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { CustomLink } from "@/components/ui/link"

export const MailerliteForm = () => {
  useEffect(() => {
    // Function to add the script dynamically
    const addScript = (src: string) => {
      const script = document.createElement("script")
      script.src = src
      script.type = "text/javascript"
      document.body.appendChild(script)
    }

    // Add the main webform script
    addScript(
      "https://static.mailerlite.com/js/w/webforms.min.js?vd4de52e171e8eb9c47c0c20caf367ddf"
    )

    // Define the success callback function
    const mlWebformSuccess = () => {
      const $ = window.jQuery || window.ml_jQuery
      if ($) {
        $(".ml-subscribe-form-6053737 .row-success").show()
        $(".ml-subscribe-form-6053737 .row-form").hide()
      }
    }

    // Attach the success function to the global object
    window.ml_webform_success_6053737 = mlWebformSuccess

    return () => {
      // Cleanup if the component unmounts
      delete window.ml_webform_success_6053737
    }
  }, [])

  return (
    <div
      id="mlb2-6053737"
      className="ml-subscribe-form ml-subscribe-form-6053737 static mx-auto my-0 box-border table w-full text-center"
    >
      <div className="relative m-0 box-border inline-block w-full p-0">
        <form
          className="row-form m-0 w-full text-left"
          action="https://static.mailerlite.com/webforms/submit/z1e9e2"
          data-code="z1e9e2"
          method="post"
          target="_blank"
        >
          <div className="m-0 mb-5 w-full">
            <div className="ml-form-fieldRow mb-3 w-full">
              <div className="ml-field-email ml-validate-email ml-validate-required">
                <Label htmlFor="email">E-mail</Label>
                <Input
                  id="email"
                  aria-label="email"
                  aria-required="true"
                  type="email"
                  data-inputmask=""
                  name="fields[email]"
                  placeholder=""
                  autoComplete="email"
                />
              </div>
            </div>
            <div className="ml-form-fieldRow m-0 mb-3 w-full">
              <div className="ml-field-name ml-validate-required">
                <Label htmlFor="name">Imię</Label>
                <Input
                  id="name"
                  aria-label="name"
                  aria-required="true"
                  type="text"
                  data-inputmask=""
                  name="fields[name]"
                  placeholder=""
                  autoComplete="given-name"
                />
              </div>
            </div>
          </div>
          <div className="ml-form-checkboxRow ml-validate-required float-left mb-5 w-full">
            <Label
              className="relative m-0 block min-h-[24px] p-0 pl-6 font-normal"
              htmlFor="checkbox"
            >
              <Input
                id="checkbox"
                type="checkbox"
                className="absolute z-[-1] -ml-6 mt-[5px] box-border overflow-visible p-0 opacity-0"
              />
              <div
                className={cn(
                  "label-description",
                  "after:content-[' '] after:absolute after:-left-6 after:top-0 after:block after:size-4 after:[background:no-repeat_50%/50%_50%]",
                  "relative mb-0 block text-left align-top text-[12px]",
                  "before:pointer-events-none before:absolute before:-left-6 before:box-border before:block before:size-4 before:rounded-sm before:content-['']",
                  "before:border-input before:border before:bg-transparent before:shadow-sm before:transition-colors"
                )}
              >
                <p className="my-0 ml-0 mr-1 p-0 text-xs font-normal leading-5">
                  Wyrażam zgodę na przetwarzanie swoich danych w celu wysyłki newslettera. Po
                  zapisie będziesz otrzymywać oferty marketingowe, informacje dietetyczne, a także
                  informacje o nowych produktach. W każdej chwili możesz się wypisać. Więcej
                  informacji znajdziesz w&nbsp;
                  <CustomLink
                    className="text-primary-500 font-semibold underline"
                    href="https://zdrowy-sukces.pl/polityka-prywatnosci"
                    prefetch={false}
                    target="_blank"
                    title="Polityka prywatności"
                    rel={"noreferrer noopener"}
                    ariaLabel={"Przejdź do polityki prywatności"}
                  >
                    polityce prywatności
                  </CustomLink>
                  &nbsp;oraz&nbsp;
                  <CustomLink
                    className="text-primary-500 font-semibold underline"
                    href="https://zdrowy-sukces.pl/regulamin"
                    prefetch={false}
                    target="_blank"
                    title="Regulamin"
                    rel={"noreferrer noopener"}
                    ariaLabel={"Przejdź do regulaminu"}
                  >
                    regulaminie
                  </CustomLink>
                  &nbsp;dostępnym na stronie&nbsp;
                  <CustomLink
                    className="text-primary-500 font-semibold underline"
                    href="https://zdrowy-sukces.pl"
                    prefetch={false}
                    target="_blank"
                    title="Strona główna"
                    rel={"noreferrer noopener"}
                    ariaLabel={"Przejdź do strony głównej"}
                  >
                    www.zdrowy-sukces.pl
                  </CustomLink>
                  . Dane przekazujesz firmie Zdrowy Sukces Patrycja Marszalec, Luchów Górny 60,
                  23-420 Tarnogród. NIP: 9182176228, REGON: 520895069.
                </p>
              </div>
            </Label>
          </div>
          <Input type="hidden" name="ml-submit" value="1" />
          <div className="group/button relative mx-0 mb-5 mt-0 flex w-full items-center justify-center md:float-left md:block">
            <Button
              type="submit"
              variant={"default"}
              className="group-hover/button:bg-primary-600 w-fit transition duration-200"
            >
              Zapisuję się i odbieram listę badań
            </Button>
            <button
              style={{ display: "none" }}
              disabled
              type="button"
              className="loading bg-primary-500 group-hover/button:bg-primary-600 absolute left-1/2 top-2 flex w-[260px] -translate-x-1/2 items-center justify-center p-1 transition duration-200 md:left-0 md:-translate-x-0"
            >
              <div className="after:content-[' '] after:m-px after:block after:size-[11px] after:animate-spin after:rounded-full after:border-2 after:border-solid after:border-[#fff_#fff_#fff_transparent]" />
              <span className="sr-only">Loading...</span>
            </button>
          </div>
          <Input type="hidden" name="anticsrf" value="true" />
        </form>
        <div className="row-success" style={{ display: "none" }}>
          <div className="mx-0 mb-5 mt-0 text-left">
            <h4 className="text-primary-500 mx-0 mb-2.5 mt-0 break-words text-center text-3xl font-bold">
              Gratulacje!
            </h4>
            <p className="mx-0 mb-2.5 mt-0 text-center text-sm font-normal leading-5 last-of-type:mb-0">
              Właśnie zapisałeś się do mojego newslettera! Aby dokończyć rejestrację, sprawdź swoją
              skrzynkę mailową i kliknij w link potwierdzający. Cieszę się, że jesteś ze mną!
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
